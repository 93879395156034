<template>
    <div>
        <venueList :sport_id="sport_id"></venueList>
    </div>
</template>

<script>

import venueList from '@/components/ballsports/venue/list.vue';

export default {
    components: {
        venueList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 11,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>