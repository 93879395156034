<template>
    <div>
        <Modal
            v-model="modal_add_sub"
            title="添加主队"
            @on-ok="confirmAddSubTeam"
        >
            <teamSearch v-model="new_team" :sport_id="sport_id" ></teamSearch>
        </Modal>
        <Drawer
            v-model="drawer_trans"
            width="450"
            :mask-closable="true"
            :transfer="true"
        >
            <venueTransEdit :sport_id="sport_id" :venue_id="trans_id" @closePage="closeTransPage"></venueTransEdit>
        </Drawer> 
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="主队: ">
                    <Row >
                        <Col span="20" style="text-align:left;" >
                            <Row :class="locks[String(type)+'-1-'+'home_team_ids'] === 1? 'locked-row': '' ">
                                <Col span="8" v-for="(item, _index) in (data.home_teams || [])" :key="item.id">
                                    <Row class="demo-upload-list" >
                                        <img :src="item.logo? $conf.ballsports.img_prefix + '/v2/team/' + item.logo: '' " />
                                        <div class="demo-upload-list-cover" >
                                            <Icon type="ios-trash-outline" @click.native="handleRemoveSub(_index)"></Icon>
                                        </div>
                                    </Row>
                                    <Row>
                                        <span class="text-assist" >{{item.name}}</span>
                                    </Row>
                                </Col>
                                <Col class="demo-upload-list">
                                    <Button shape="circle" icon="md-add" @click="modal_add_sub = true" ></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'sub_ids']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="sub_ids"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="名称: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.name" :class="locks[String(type)+'-1-'+'name'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'name']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="name"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="城市: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.city" :class="locks[String(type)+'-1-'+'city'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'city']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="city"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="建造年份: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.constr_year" :class="locks[String(type)+'-1-'+'constr_year'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'constr_year']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="constr_year"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="容量: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.capacity" :class="locks[String(type)+'-1-'+'capacity'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'capacity']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="capacity"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="国家: ">
                    <Row>
                        <Col span="20" >
                            <countrySearch :sport_id="sport_id" v-model="data.country" :class="locks[String(type)+'-1-'+'country_id'] === 1? 'locked-input': '' " ></countrySearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'country_id']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="country_id"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="venue_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="全称翻译: ">
                    <Row>
                        <Col span="20" >
                            <Button type="default" long @click="editTrans">翻译</Button>
                        </Col>
                    </Row>
                </FormItem>
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import countrySearch from "../country/search.vue";
import Lock from '../lock.vue';
import { getVenue, updateVenue } from '@/api/ballsports/base/venue';
import teamSearch from "../team/search.vue";
// import moment from 'moment';
// import imgEditor from '@/components/img-editor/img-editor.vue';
import venueTransEdit from './trans/edit.vue';

export default {
    name: "venue-edit",
    props: {
        sport_id: Number,   // 运动id
        venue_id: Number,   // 联赛id
    },
    components: {
        Lock,
        countrySearch,
        teamSearch,
        // imgEditor,
        venueTransEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 7,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                name:'',
                country: {},
                deleted: 0,
            },
            locks: {},
            modal_add_sub: false,
            new_team: {
                "id": 0,
                "name": '',
                "logo": '',
            },
            drawer_trans: false,
            trans_id: 0,
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.venue_id && self.venue_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.venue_id,
                }
                getVenue( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // self.locks = response.data.data.locks ;
                            self.locks = {}
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.locks = {}
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;
            let home_team_ids = []
            if (self.data.home_teams && self.data.home_teams.length > 0){
                self.data.home_teams.forEach(element => {
                    home_team_ids.push(element.id)
                });
            }

            let params = {
                id: self.data.id,
                sport_id: self.data.sport_id || self.sport_id,
                name: self.data.name,
                city: self.data.city,
                capacity: self.data.capacity,
                home_team_ids: home_team_ids,
                country_id: self.data.country.id,
                constr_year: self.data.constr_year,
                deleted: self.data.deleted,
            }
            updateVenue(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
        confirmAddSubTeam(){
            // 添加子球队
            let self = this;
            if (! self.data.home_teams){
                self.data.home_teams = [];
            }
            self.data.home_teams.push(self.new_team);
        },
        // end 
        handleRemoveSub(_index){
            // 根据索引移除子队伍
            let self = this;
            self.data.home_teams.splice(_index, 1);
        },
        // end
        editTrans(){
            let self = this;
            self.drawer_trans = true;
            self.trans_id = self.venue_id;
        },
        // end 
        closeTransPage(){
            let self = this;
            self.drawer_trans = false;
            self.trans_id = 0;
        },
        // end
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "venue_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>